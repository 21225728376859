import {store} from "../store/store"
import {setNotification} from "../store/features/notification.slice"
import {AxiosError} from "axios"
import {removeLocalStorageItem} from "./localStore.service"


export function responseNotificationCreator(event: AxiosError | any) {

    store.dispatch(setNotification({
        code: event?.response?.status || event?.code || 500,
        isShow: true,
        message: event?.response?.data || event?.message || 'Неизвестная ошибка',
    }))

    setTimeout(() => {
        store.dispatch(setNotification({
            code: null,
            isShow: false,
            message: '',
        }))
    }, 5000)

    if (event?.response?.status === 401) {
        removeLocalStorageItem('user');
        // TODO костыль для редиректа вне компонента, данный момент необходимо проработать
        setTimeout(() => {
            window.location = `${process.env.REACT_APP_APP_URL}/auth/login-mail` as any
        }, 100)
    }

}
