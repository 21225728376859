import { PaymentsData } from "../models/types/payments.interface"
import { FieldValues } from "react-hook-form"
import {axiosInstance} from "./main.interceptor"
import {AxiosResponse} from "axios";


function generateRequest() {
    return axiosInstance()
}

// TODO change format first_name and last name to NAME!!!!!!!!!!!!
// TODO types
/* ? Login / Registration==========================================*/
    export async function Registration(data: any): Promise<any> {
        return await generateRequest()
            .post(`/api/auth/registration`, data);
    }
export async function ConfirmRegistrationPhone(data: any): Promise<any> {
    return await generateRequest()
        .post(`/api/auth/confirm-phone`, data);
}

    export async function LoginMail(data: any): Promise<any> {
        return await generateRequest()
            .post(`/api/auth/login-mail`, data);
    }

    export async function LoginPhone(data: any): Promise<AxiosResponse<{codeActivate: number}>> {
        return await generateRequest()
            .post(`/api/auth/login-phone`, data);
    }
    export async function LoginPhoneConfirm(data: any): Promise<any> {
        return await generateRequest()
            .post(`/api/auth/login-phone-confirm`, data);
    }
/* ? Login / Registration==========================================*/

/* ? Get Data======================================================*/
    export async function GetAllContracts(data: any): Promise<any> {
        return await generateRequest()
            .post(`/api/get/all_contracts`, data);
    }
    export async function GetItemContracts(data: any): Promise<any> {
        return await generateRequest()
            .post(`/api/get/item_contract`, data);
    }
    export async function GetPersonalData(data: {index: string}): Promise<any> {
        return await generateRequest()
            .post(`/api/get/personal_data`, data);
    }
/* ? Get Data======================================================*/

/* ? Payments======================================================*/
    export async function GetPaymentsForm(data: FieldValues | PaymentsData): Promise<any> {
        return await generateRequest()
            .post(`/api/payments`, data);
    }
    export async function SuccessfulPayments(data: FieldValues | {orderNumber: string}): Promise<any> {
        return await generateRequest()
            .post(`/api/payments-successful`, data);
    }
/* ? Payments======================================================*/

/* ? Payments-without-reg==========================================*/
    export async function GetPaymentsFormWithoutReg(data: FieldValues | PaymentsData): Promise<any> {
        return await generateRequest()
            .post(`/api/payments-without-reg`, data);
    }
    export async function SuccessfulPaymentsWithoutReg(data: FieldValues | {orderNumber: string}): Promise<any> {
        return await generateRequest()
            .post(`/api/payments-without-reg-successful`, data);
    }
/* ? Payments-without-reg==========================================*/

/* ? Feedback======================================================*/
    export async function FeedbackSend(data: any): Promise<any> {
        return await generateRequest()
            .post(`/api/feedback`, data);
    }
/* ? Feedback======================================================*/
