export const getLocalStorageItem = (key: string): any => {
    return localStorage.getItem(key)
}

// TODO: типизация
export const setLocalStorageItem = (key: string, value: any): any => {
    return localStorage.setItem(key, JSON.stringify(value))
}

export const removeLocalStorageItem = (key: string): any => {
    return localStorage.removeItem(key)
}