import style from './new-contract-dialog.module.scss'
import {Button, Dialog, InputAdornment, Stack, useTheme} from "@mui/material"
import DialogTitle from '@mui/material/DialogTitle'
import TextField from "@mui/material/TextField"
import invalid from "../../assets/img/invalid.svg"
import valid from "../../assets/img/valid.svg"
import React, {ReactNode, useEffect, useState} from "react"
import {Controller, FieldValues, useForm} from "react-hook-form"
import ReactInputMask from "react-input-mask"
import {UserInterface} from "../../models/types/user.interface";
import {useSelector} from "react-redux";

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    onCloseWithChange: (value: FieldValues) => void;
}


const NewContractDialog = (props: SimpleDialogProps) => {

    const personalData: UserInterface = useSelector((state: any) => state?.userData?.data)

    const {custom} = useTheme()
    const { onClose, onCloseWithChange, open } = props

    const handleClose = () => {
        onClose()
    }

    /*? VALIDATION-----------------------------------*/
        const {
            control, register, handleSubmit, reset,
            formState: {
                errors, isValid, dirtyFields
            }
        } = useForm({mode: "onChange"})
        const onSubmit = (data: FieldValues) => {
            onCloseWithChange(data)
        }
    /*? VALIDATION-----------------------------------*/

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Оставьте контактные данные для связи с менеджером</DialogTitle>
            <div className={style.body}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={3} direction="column">
                        <TextField
                            {...register('name', {required: 'Поле не  может быть пустым'})}
                            variant="outlined" label="Ваше имя"
                            defaultValue={personalData?.name}
                            error={!!errors?.name}
                            helperText={!!errors?.name ? String(errors?.name?.message) : ''}
                            InputProps={{
                                sx: { height: 50, width: 500},
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        {errors?.name && <img src={invalid}/>}
                                        {dirtyFields?.name && !errors?.name && <img src={valid}/>}
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Controller
                            control={control}
                            name={'phone'}
                            defaultValue={personalData?.phone}
                            rules={{
                                required: 'Поле не  может быть пустым',
                            }}
                            render={({ field }) => {
                                return (<ReactInputMask
                                        mask="+7 999 999 99 99"
                                        disabled={false}
                                        value={field.value}
                                        onChange={field.onChange}
                                    >{(() => <TextField
                                        variant="outlined" label="Ваш номер телефона"
                                        error={!!errors?.phone}
                                        helperText={!!errors?.phone ? String(errors?.phone?.message) : ''}
                                        InputProps={{
                                            sx: {height: 50, width: 500},
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    {errors?.phone && <img src={invalid}/>}
                                                    {dirtyFields?.phone && !errors?.phone && <img src={valid}/>}
                                                </InputAdornment>
                                            )}}
                                    />) as any as ReactNode}
                                    </ReactInputMask>
                                )
                            }
                            }
                        />
                    </Stack>
                    <Button variant="contained" className={style.body_btn + ' fnt-mull-500 btn-main'}
                            type='submit'
                            sx={{bgcolor: custom.buttonActivity.bgColor,
                                color: custom.buttonActivity.color,
                                border: custom.buttonActivity.border,
                                "&:hover": {
                                    color: custom.buttonActivity.hover.color,
                                    bgcolor: custom.buttonActivity.hover.bgColor,
                                },
                                "&:active": {
                                    color: custom.buttonActivity.active.color,
                                    bgcolor: custom.buttonActivity.active.bgColor
                                },
                            }}
                    >Продолжить</Button>
                </form>
            </div>
        </Dialog>
    )
}

export default NewContractDialog
