import {store} from "../store"
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {GetPersonalData} from "../../services/http.service"
import {UserInterface} from "../../models/types/user.interface"

export const userDataAsyncSlice = createAsyncThunk(
    'userData/userDataAsyncSlice',
    async (data: {index: string}) =>{
        return GetPersonalData(data)
    }
)

const UserDataSlice = createSlice({
    name: 'userData',
    initialState: {
        data: {} as UserInterface
    },
    extraReducers: {
        [<any>userDataAsyncSlice.fulfilled]: (state: { data: UserInterface }, action: any) => {
            state.data = action.payload.data
        }
    },
    reducers: {}
})

export type AppDispatch = typeof store.dispatch
export default UserDataSlice.reducer
