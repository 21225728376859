import {getLocalStorageItem, setLocalStorageItem} from "./localStore.service"
import {LoginMail, LoginPhoneConfirm, Registration} from "./http.service"
import {LoginMethodEnum} from "../models/enums/login-method-enum";

// TODO: типизация
export const UserAuthorization = async (data?: any): Promise<any> => {
    return Registration(data)
}

// TODO: типизация
export const UserLogin = async (data?: any, method?: LoginMethodEnum.phone | LoginMethodEnum.mail): Promise<boolean> => {
    if (method === LoginMethodEnum.mail) {
        return await LoginMail(data).then(res => {
            // TODO: типизация
            const activeUser = {
                index: res.data?.data?.index,
                token: res.data?.data?.token
            }
            setLocalStorageItem('user', activeUser)
            return true
        })
    }
    if (method === LoginMethodEnum.phone) {
        return await LoginPhoneConfirm(data).then(res => {
            // TODO: типизация
            const activeUser = {
                index: res.data?.data?.index,
                token: res.data?.data?.token
            }
            setLocalStorageItem('user', activeUser)
            return true
        })
    }
    return false
}

// TODO: типизация
export const UserCheck = (): any => {
    return getLocalStorageItem('user')
}

