import style from './new-service.module.scss'
import {useTheme} from "@mui/material"
import {useState} from "react"
import NewContractDialog from "../new-contract-dialog/new-contract-dialog"
import {FieldValues} from "react-hook-form"
import {FeedbackSend} from "../../services/http.service";
import {responseNotificationCreator} from "../../services/error-handler.service";

const NewService = () => {
    const {custom} = useTheme()

    const [open, setOpen] = useState<boolean>(false)

    /*? DIALOG-MODAL--------------------------------*/
        const onOpenDialog = (): void => {
            setOpen(true)
        }
        const onCloseDialog = (): void => {
            setOpen(false)
        }
        const onCloseDialogWithChange = (value: FieldValues): void => {
            FeedbackSend(value).then(() => {
                responseNotificationCreator({code: 200, message: 'Заявка успешно отправлена'})
            })
            setOpen(false)
        }
    /*? DIALOG-MODAL--------------------------------*/

    return (
        <div className={style.main} style={{border: `1px solid #e0e0e0`}}>

            <div className={style.main_txt}>
                <h1 className={'fnt-mull-400'}
                    style={{color: custom.text.colorMain}}
                >Подключите новую<br/> услугу</h1>
                <p className={'fnt-mull-400'}
                   style={{color: custom.text.colorMain}}
                >Вам не придется беспокоиться<br/> о сохраности вашего имущества!</p>
            </div>

            <div className={style.main_img} onClick={onOpenDialog}>
                <div className={style.main_img_horizontal}></div>
                <div className={style.main_img_vertical}></div>
            </div>

            <NewContractDialog
                open={open}
                onClose={onCloseDialog}
                onCloseWithChange={onCloseDialogWithChange}
            />

        </div>
    )
}

export default NewService
