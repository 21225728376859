import {configureStore} from "@reduxjs/toolkit"
import Notification from "./features/notification.slice"
import UserData from './features/user-data.slice'
import ActualContract from './features/actual-contract.slice'

export const store = configureStore({
    reducer: {
        notification: Notification,
        userData: UserData,
        actualContract: ActualContract,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})
