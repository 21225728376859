import style from './login-mail.module.scss'
import {Button, InputAdornment, Stack, useTheme} from "@mui/material"
import TextField from "@mui/material/TextField"
import {inputEmail, inputPassword} from "../../../models/static/input-validation-template"
import invalid from "../../../assets/img/invalid.svg"
import valid from "../../../assets/img/valid.svg"
import React from "react"
import {useForm} from "react-hook-form"
import {UserLogin} from "../../../services/user.service"
import {useNavigate} from "react-router-dom"
import {LoginMethodEnum} from "../../../models/enums/login-method-enum";

const LoginMailComponent = () => {

    const navigate = useNavigate()
    const {custom} = useTheme()

    /*? VALIDATION-----------------------------------*/
        const {
            register, handleSubmit, reset,
            formState: {
                errors, isValid, dirtyFields
            }
        } = useForm({mode: "onChange"})
        // TODO types
        const onSubmit = (data: any) => {
            UserLogin(data, LoginMethodEnum.mail).then(() => navigate('/'))
            reset()
        }
    /*? VALIDATION-----------------------------------*/

    return (
        <div className={style.login_mail}>
                <h2 className={style.login_mail__heading + ' fnt-mull-400'}
                    style={{color: custom.text.colorMain}}
                >Вход в личный кабинет<br/> по почте</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div style={{marginBottom: '30px'}}>
                        <Stack spacing={3} direction="column">
                            <TextField
                                {...register('mail', inputEmail)}
                                variant="outlined" label="Ваш e-mail"
                                error={!!errors?.mail}
                                helperText={!!errors?.mail ? String(errors?.mail?.message) : ''}
                                InputProps={{
                                    sx: {height: 50, width: '100%',
                                        '@media (min-width: 600px)': { width: '400px'},
                                        '@media (min-width: 750px)': {width: '700px'}},
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            {errors?.mail && <img src={invalid}/>}
                                            {dirtyFields?.mail && !errors?.mail && <img src={valid}/>}
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                {...register('password', inputPassword)}
                                variant="outlined" label="Введите пароль"
                                error={!!errors?.password}
                                helperText={!!errors?.password ? String(errors?.password?.message) : ''}
                                InputProps={{
                                    sx: {height: 50, width: '100%',
                                        '@media (min-width: 600px)': { width: '400px'},
                                        '@media (min-width: 750px)': {width: '700px'}},
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            {errors?.password && <img src={invalid}/>}
                                            {dirtyFields?.password && !errors?.password && <img src={valid}/>}
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Stack>
                    </div>
                    {/*<div style={{color: custom.text.colorMain, borderBottom: `1px solid ${custom.text.colorMain}`}}*/}
                    {/*     className={style.login_mail__activity_pass + ' fnt-mull-400'}*/}
                    {/*     onClick={() => {*/}
                    {/*         navigate('/auth/confirm')*/}
                    {/*     }}*/}
                    {/*>Забыли пароль?</div><br/>*/}
                    <Button variant="contained" className={style.login_mail__activity_btn + ' fnt-mull-500 btn-main'}
                            type='submit'
                            sx={{bgcolor: custom.buttonActivity.bgColor,
                                color: custom.buttonActivity.color,
                                border: custom.buttonActivity.border,
                                "&:hover": {
                                    color: custom.buttonActivity.hover.color,
                                    bgcolor: custom.buttonActivity.hover.bgColor,
                                },
                                "&:active": {
                                    color: custom.buttonActivity.active.color,
                                    bgcolor: custom.buttonActivity.active.bgColor
                                },
                            }}
                    >Продолжить</Button>
                </form>
                <div className={style.login_mail__desc + ' fnt-mull-400'}
                     onClick={() => navigate('/payment-without-registration')}
                >Вы так же можете пополнить баланс&nbsp;
                    <span style={{color: custom.text.colorMain, borderBottom: `1px solid ${custom.text.colorMain}`}}
                    >без регистрации</span>
                </div>
        </div>
    )
}

export default LoginMailComponent
