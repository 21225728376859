import style from './payments-unsuccessful.module.scss'
import { useTheme } from "@mui/material"
import React from "react";
import invalid from "../../../../assets/img/invalid.svg"

const PaymentsUnsuccessfulComponent = () => {
    const {custom} = useTheme()

    return (
        <div className={style.main}>

            <div className={style.main_content}>
                <img src={invalid}/>
                <span className={style.main_content__heading + ' fnt-mull-400'}
                      style={{color: custom.text.colorMin}}
                >Ошибка платежа...</span>
            </div>

        </div>
    );
};

export default PaymentsUnsuccessfulComponent;
