import style from './contract.module.scss'
import {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import {Button, Stack, useTheme} from "@mui/material"
import HeaderPersonal from "../../../UI/header_personal/header-personal"
import {HeaderPersonalProps} from "../../../models/types/types-props"
import {GetItemContracts} from "../../../services/http.service"
import {UserInterface} from "../../../models/types/user.interface"
import {useDispatch, useSelector} from "react-redux"
import {ContractInterface} from "../../../models/types/contract.interface"
import card from "../../../assets/img/card.svg"
import no_protection from "../../../assets/img/no_protection.svg"
import protection from "../../../assets/img/protection.svg"
import moment from "moment"
import {AppDispatch} from "../../../store/features/user-data.slice"
import {setContract} from "../../../store/features/actual-contract.slice"

const ContractComponent = () => {

    const dispatch = useDispatch<AppDispatch>()
    const {custom} = useTheme()
    const navigate = useNavigate()

    const [headerPersonalPropsState, setHeaderPersonalPropsState] = useState<HeaderPersonalProps>()
    const [contractData, setContractData] = useState<ContractInterface>()
    const personalData: UserInterface = useSelector((state: any) => state.userData.data)

    //TODO: types
    useEffect(() => {
        const contractIndex = decodeURIComponent(window.location.pathname.split('/contract/')[1])
        GetItemContracts({index: contractIndex}).then(({data}: any) => {
            setContractData(data)
            setHeaderPersonalPropsState({
                heading: data.number
            })
        })
    }, [])

    const everyDayCharge = (quantity: number) => {
        const dayInMonth = moment().daysInMonth()
        return (dayInMonth / quantity).toFixed(1)
    }

    const buttonNavigation = (rout: string): void => {
        dispatch(setContract({number: contractData?.number}))
        navigate(rout)
    }


    return (
        <div className={style.contract}>
            {
                headerPersonalPropsState
                && <HeaderPersonal {...headerPersonalPropsState}></HeaderPersonal>
            }
            {
                contractData && personalData
                && <div>

                    <div className={style.detailing}>
                        <div className={style.detailing_balance}>
                            <p className={style.detailing_balance_name + ' fnt-mull-400'}
                               style={{color: custom.text.colorDesc}}
                            >Баланс</p>
                            <p className={style.detailing_balance_quantity + ' fnt-mull-400'}
                               style={contractData.balance <= 0 ? {color: custom.text.colorMin} : {color: custom.text.colorFull}}
                            >{contractData.balance} ₽</p>
                            <div className={style.detailing_balance_btn}>
                                <Stack spacing={2} direction="row">
                                    <Button variant="contained" className={'fnt-mull-500 btn-main'}
                                            onClick={() => buttonNavigation('/balance')}
                                            sx={{
                                                bgcolor: custom.buttonActivity.bgColor,
                                                color: custom.buttonActivity.color,
                                                border: custom.buttonActivity.border,
                                                padding: '10px',
                                                height: '30px',
                                                "&:hover": {
                                                    color: custom.buttonActivity.hover.color,
                                                    bgcolor: custom.buttonActivity.hover.bgColor,
                                                },
                                                "&:active": {
                                                    color: custom.buttonActivity.active.color,
                                                    bgcolor: custom.buttonActivity.active.bgColor
                                                },
                                            }}
                                    >Оплатить<span><img src={card} alt="card"/></span></Button>
                                    {/*TODO: возможно добавить позже*/}
                                    {/*<Button variant="contained" className={'fnt-mull-500 btn-main'}*/}
                                    {/*        sx={{bgcolor: 'transparent',*/}
                                    {/*            color: custom.buttonHeader.color,*/}
                                    {/*            border: custom.buttonHeader.border,*/}
                                    {/*            padding: '10px',*/}
                                    {/*            height: '30px',*/}
                                    {/*            "&:hover": {*/}
                                    {/*                color: custom.buttonHeader.hover.color,*/}
                                    {/*                bgcolor: custom.buttonHeader.hover.bgColor,*/}
                                    {/*            },*/}
                                    {/*            "&:active": {*/}
                                    {/*                color: custom.buttonHeader.active.color,*/}
                                    {/*                bgcolor: custom.buttonHeader.active.bgColor*/}
                                    {/*            },*/}
                                    {/*        }}*/}
                                    {/*>Прервать</Button>*/}
                                </Stack>
                            </div>
                        </div>
                        <div className={style.detailing_status}>
                            <div>
                                <p className={style.detailing_status_heading + ' fnt-mull-400'}
                                   style={{color: custom.text.colorDesc}}
                                >Статус объекта</p>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <img style={{transform: "translateY(-5px)", marginRight: '5px'}}
                                         src={contractData.balance <= 0 ? no_protection : protection} alt="protection"/>
                                    <p className={style.detailing_status_description + ' fnt-mull-500'}
                                       style={contractData.balance <= 0 ? {color: custom.text.colorMin} : {color: custom.text.colorFull}}
                                    >{contractData.balance <= 0 ? 'Без защиты' : 'Под защитой'}</p>
                                </div>
                            </div>
                            <div>
                                {contractData.tariff
                                    && <p className={style.detailing_status_heading + ' fnt-mull-400'}
                                          style={{color: custom.text.colorDesc}}
                                    >Тариф</p>}
                                {contractData.tariff
                                    && <p className={style.detailing_status_description + ' fnt-mull-500'}
                                          style={{color: custom.text.colorFull}}
                                    >{contractData.tariff}</p>}
                            </div>
                            <div>
                                <p className={style.detailing_status_heading + ' fnt-mull-400'}
                                   style={{color: custom.text.colorDesc}}
                                >Стоимость тарифа</p>
                                <p className={style.detailing_status_description + ' fnt-mull-500'}
                                   style={{color: custom.text.colorMain}}
                                >{contractData.monthly_charge + ' ₽ в месяц'}</p>
                            </div>
                        </div>
                    </div>

                    <hr/>

                    <div className={style.specification}>
                        <div>
                            <p className={style.specification_heading + ' fnt-mull-400'}
                               style={{color: custom.text.colorDesc}}
                            >Договор</p>
                            <p className={style.specification_description + ' fnt-mull-400'}
                               style={{color: custom.text.colorMain}}
                            >{'№ ' + contractData.index || 'Не указано'}</p>
                        </div>
                        <div>
                            <p className={style.specification_heading + ' fnt-mull-400'}
                               style={{color: custom.text.colorDesc}}
                            >Адрес объекта</p>
                            <p className={style.specification_description + ' fnt-mull-400'}
                               style={{color: custom.text.colorMain}}
                            >{contractData.address || 'Не указано'}</p>
                        </div>
                        <div>
                            <p className={style.specification_heading + ' fnt-mull-400'}
                               style={{color: custom.text.colorDesc}}
                            >Ф.И.О. Владельца</p>
                            <p className={style.specification_description + ' fnt-mull-400'}
                               style={{color: custom.text.colorMain}}
                            >{personalData.name || 'Не указано'}</p>
                        </div>
                        {/*//TODO: стоит внедрить позже если будет необходимость блокировать учетки*/}
                        {/*<div>*/}
                        {/*    <p className={style.specification_heading + ' fnt-mull-400'}*/}
                        {/*       style={{color: custom.text.colorDesc}}*/}
                        {/*    >Зарегистрирован</p>*/}
                        {/*    /!* TODO: стоит добавить в таблицу сущность даты регистрации*!/*/}
                        {/*    <p className={style.specification_description + ' fnt-mull-400'}*/}
                        {/*       style={{color: custom.text.colorMain}}*/}
                        {/*    >{'Не указано'}</p>*/}
                        {/*</div>*/}
                    </div>

                </div>
            }

        </div>
    )
}

export default ContractComponent
