import style from './payment-without-regist.module.scss'
import {Button, InputAdornment, Stack, useTheme} from "@mui/material"
import {useNavigate} from "react-router-dom"
import logo_black from "../../assets/img/logo_black.png"
import TextField from "@mui/material/TextField"
import {inputAmount, inputName, inputPassword} from "../../models/static/input-validation-template"
import invalid from "../../assets/img/invalid.svg"
import valid from "../../assets/img/valid.svg"
import {Controller, FieldValues, useForm} from "react-hook-form"
import ReactInputMask from "react-input-mask"
import React, {ReactNode} from "react"
import Switch from "@mui/material/Switch"
import {PaymentsData} from "../../models/types/payments.interface"
import {GetPaymentsFormWithoutReg} from "../../services/http.service"

const PaymentWithoutRegistComponent = () => {

    const {custom} = useTheme()
    const navigate = useNavigate()

    /*? VALIDATION-----------------------------------*/
        const {
            control, register, handleSubmit, reset, setError, clearErrors,
            formState: {
                errors, isValid, dirtyFields
            }
        } = useForm({mode: "onChange"})
        // TODO types
        const onSubmit = async (value: any) => {
            delete value.checkbox
            createPayment(value)
        }

        const validateMailAndPhone = () => {
            if (!control._formValues.mail && !control._formValues.phone) {
                setError("mail", {
                    type: "manual",
                    message: "Необходиом ввести mail или телефон"
                })
                setError("phone", {
                    type: "manual",
                    message: "Необходиом ввести mail или телефон"
                })
            } else {
                clearErrors('mail')
                clearErrors('phone')
            }
        }
    /*? VALIDATION-----------------------------------*/

    /*? PAYMENTS-------------------------------------*/
    const createPayment = (data: FieldValues | PaymentsData) => {
        // TODO types
        GetPaymentsFormWithoutReg(data).then((res: any) => {
            reset()
            window.open(res.data.formUrl, '_blank')
        })
    }
    /*? PAYMENTS-------------------------------------*/

    return (
        <div className={style.payments}>
            {/* ? HEADER*/}
            <div className={style.payments_header}>
                <div className={style.payments_header__heading + ' fnt-mull-500'}
                     style={{color: custom.text.colorMain}}
                     onClick={() => navigate('/auth/login-mail')}
                >Блок 54</div>
                <div className={style.payments_header__btn}>
                    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                        <Button variant="contained" className={'fnt-mull-500 btn-main'}
                                sx={{bgcolor: custom.buttonHeader.bgColor,
                                    color: custom.buttonHeader.color,
                                    border: custom.buttonHeader.border,
                                    "&:hover": {
                                        color: custom.buttonHeader.hover.color,
                                        bgcolor: custom.buttonHeader.hover.bgColor,
                                    },
                                    "&:active": {
                                        color: custom.buttonHeader.active.color,
                                        bgcolor: custom.buttonHeader.active.bgColor
                                    },
                                }}
                                onClick={() => {
                                    window.location.href = 'tel:83832583300'
                                }}
                        >+7 383 258-33-00</Button>
                        <Button variant="contained" className={'fnt-mull-500 btn-main'}
                                sx={{bgcolor: custom.buttonHeader.bgColor,
                                    color: custom.buttonHeader.color,
                                    border: custom.buttonHeader.border,
                                    "&:hover": {
                                        color: custom.buttonHeader.hover.color,
                                        bgcolor: custom.buttonHeader.hover.bgColor,
                                    },
                                    "&:active": {
                                        color: custom.buttonHeader.active.color,
                                        bgcolor: custom.buttonHeader.active.bgColor
                                    },
                                }}
                                href="https://block54.ru"
                        >Перейти на сайт</Button>
                    </Stack>
                </div>
            </div>

            {/* ? CONTENT*/}
            <div className={style.payments__content}>
                <h2 className={style.payments__content_heading + ' fnt-mull-400'}
                    style={{color: custom.text.colorMain}}
                >Введите необходимые данные</h2>
                <form onSubmit={handleSubmit(onSubmit)} onChange={() => validateMailAndPhone()}>
                    <Stack spacing={4} direction="row" style={{marginTop: '24px'}}>
                        {/** FIRST-NAME-------------------*/}
                        <TextField
                            {...register('firstName', inputName)}
                            variant="outlined" label="Имя"
                            onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                            error={!!errors?.firstName}
                            helperText={!!errors?.firstName ? String(errors?.firstName?.message) : ''}
                            InputProps={{
                                sx: {height: 50, width: '280px',
                                    '@media (max-width: 750px)': { width: '150px'},
                                    '@media (max-width: 450px)': {width: '150px'}},
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        {errors?.firstName && <img src={invalid}/>}
                                        {dirtyFields?.firstName && !errors?.firstName && <img src={valid}/>}
                                    </InputAdornment>
                                )
                            }}
                        />
                        {/** LAST-NAME--------------------*/}
                        <TextField
                            {...register('lastName', inputName)}
                            variant="outlined" label="Фамилия"
                            onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                            error={!!errors?.lastName}
                            helperText={!!errors?.lastName ? String(errors?.lastName?.message) : ''}
                            InputProps={{
                                sx: {height: 50, width: '453px',
                                    '@media (max-width: 750px)': { width: '217px'},
                                    '@media (max-width: 450px)': {width: '132px'}},
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        {errors?.lastName && <img src={invalid}/>}
                                        {dirtyFields?.lastName && !errors?.lastName && <img src={valid}/>}
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Stack>
                    <Stack spacing={3} direction="column" style={{marginTop: '30px'}}>
                        {/** CONTRACT---------------------*/}
                        <TextField
                            {...register('contract', {required: 'Необходимо ввести номер договора'})}
                            variant="outlined" label="Номер договора"
                            onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                            error={!!errors?.contract}
                            helperText={!!errors?.contract ? String(errors?.contract?.message) : ''}
                            InputProps={{
                                sx: {height: 50, width: '768px',
                                    '@media (max-width: 750px)': { width: '400px'},
                                    '@media (max-width: 450px)': {width: '305px'}},
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        {errors?.contract && <img src={invalid}/>}
                                        {dirtyFields?.contract && !errors?.contract && <img src={valid}/>}
                                    </InputAdornment>
                                )
                            }}
                        />
                        {/** AMOUNT------------------------*/}
                        <TextField
                            {...register('amount', inputAmount)}
                            variant="outlined" label="Введите сумму пополнения"
                            error={!!errors?.amount}
                            helperText={!!errors?.amount ? String(errors?.amount?.message) : ''}
                            InputProps={{
                                sx: {height: 50, width: '768px',
                                    '@media (max-width: 750px)': { width: '400px'},
                                    '@media (max-width: 450px)': {width: '305px'}},
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        {errors?.amount && <img src={invalid}/>}
                                        {dirtyFields?.amount && !errors?.amount && <img src={valid}/>}
                                    </InputAdornment>
                                )
                            }}
                        />
                        {/** MAIL------------------------*/}
                        <TextField
                            {...register('mail', {required: false,
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "Некорректный формат для e-mail"
                                }})}
                            variant="outlined" label="Ваш e-mail"
                            onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                            error={!!errors?.mail}
                            helperText={!!errors?.mail ? String(errors?.mail?.message) : ''}
                            InputProps={{
                                sx: {height: 50, width: '768px',
                                    '@media (max-width: 750px)': { width: '400px'},
                                    '@media (max-width: 450px)': {width: '305px'}},
                            }}
                        />
                        {/** PHONE-----------------------*/}
                        <Controller
                            control={control}
                            name={'phone'}
                            defaultValue=""
                            rules={{ required: false, }}
                            render={({ field }) => {
                                return (<ReactInputMask
                                        mask="+7 999 999 99 99"
                                        disabled={false}
                                        value={field.value}
                                        onChange={field.onChange}
                                    >{(() => <TextField
                                        variant="outlined" label="Ваш номер телефона"
                                        error={!!errors?.phone}
                                        helperText={!!errors?.phone ? String(errors?.phone?.message) : ''}
                                        InputProps={{ sx: {height: 50, width: '768px',
                                                '@media (max-width: 750px)': { width: '400px'},
                                                '@media (max-width: 450px)': {width: '305px'}}, }}
                                    />) as any as ReactNode}
                                    </ReactInputMask>
                                )
                            }
                            }
                        />
                    </Stack>
                    <div className={style.payments__content_checkbox}>
                        <Switch className={style.payments__content_checkbox_flag}
                                inputProps={{ "color": "red" }}
                                {...register('checkbox', inputPassword)}
                        />
                        <div className={style.payments__content_checkbox_desc + ' fnt-mull-400'}
                             style={{color: errors?.checkbox ? custom.text.colorError : custom.text.colorMain}}
                        >Я ознакомился и согласился с передачей и обработкой моих персональных данных</div>
                    </div>
                    <Button variant="contained" className={style.payments__content_btn + ' fnt-mull-500 btn-main'}
                            type='submit'
                            sx={{bgcolor: custom.buttonActivity.bgColor,
                                color: custom.buttonActivity.color,
                                border: custom.buttonActivity.border,
                                "&:hover": {
                                    color: custom.buttonActivity.hover.color,
                                    bgcolor: custom.buttonActivity.hover.bgColor,
                                },
                                "&:active": {
                                    color: custom.buttonActivity.active.color,
                                    bgcolor: custom.buttonActivity.active.bgColor
                                },
                            }}
                    >Продолжить</Button>
                </form>
            </div>

            {/* ? FOOTER*/}
            <div className={style.payments_footer}>
                <Stack className={style.payments_footer__logo}
                       spacing={2} direction="row"
                       alignItems="center">
                    <div className={style.payments_footer__logo_img} ><img src={logo_black}/></div>
                    <div className={style.payments_footer__logo_desc + ' fnt-mull-500'}
                         style={{color: custom.text.colorMain}}
                    >Охранное предприятие Блок 54</div>
                </Stack>
                <Stack className={style.payments_footer__btn}
                       spacing={2} direction="row">
                    <p className={style.auth_footer__btn_item + ' fnt-mull-500'}
                       onClick={() => navigate('/privacy-policy')}
                       style={{
                        cursor: 'pointer',
                        color: custom.text.colorMain,
                        borderBottom: custom.buttonFooter.borderBottomSec}}
                    >Политика Конфиденциальности</p>
                    {/*TODO: добавить в будущем*/}
                    {/*<p className={style.payments_footer__btn_item + ' fnt-mull-500'} style={{*/}
                    {/*    cursor: 'pointer',*/}
                    {/*    color: custom.text.colorMain,*/}
                    {/*    borderBottom: custom.buttonFooter.borderBottomSec}}*/}
                    {/*>Условия Пользования</p>*/}
                </Stack>
            </div>
        </div>
    );
};

export default PaymentWithoutRegistComponent;
