export const getMonthWord = (number: number) => {
    const cases = [2, 0, 1, 1, 1, 2];
    const monthWords = ['месяц', 'месяца', 'месяцев'];

    if (number % 100 >= 5 && number % 100 <= 20) {
        return monthWords[2];
    } else {
        const i = number % 10 < 5 ? number % 10 : 5;
        return monthWords[cases[i]];
    }
}