import style from './header-personal.module.scss'
import {Accordion, AccordionDetails, AccordionSummary, Button, Stack, Typography, useTheme} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import out from "../../assets/img/logout.svg"
import {removeLocalStorageItem} from "../../services/localStore.service"
import {useNavigate} from "react-router-dom"
import {HeaderPersonalProps} from "../../models/types/types-props"
import {useSelector} from "react-redux"
import {UserInterface} from "../../models/types/user.interface"


const HeaderPersonal = (props?: HeaderPersonalProps) => {

    const {custom} = useTheme()
    const navigate = useNavigate()

    const logout = (): void => {
        removeLocalStorageItem('user')
        navigate('/auth/login-mail')
    }

    const personalData: UserInterface = useSelector((state: any) => state?.userData?.data)

    return (
        <div className={style.main}>

            <div className={style.header}>
                <h1 className={style.header + ' fnt-mull-500'}
                    style={{color: custom.text.colorMain}}
                >{props?.heading}</h1>
            </div>


            <div className={style.active}>
                <div className={style.active_personal}>
                    <Accordion style={{border: `1px solid ${custom.static.light}`, borderRadius: '8px'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography component={'span'} variant={'body2'}>
                                <p className={'mt-8 fnt-mull-400'}
                                   style={{color: custom.text.colorDesc}}
                                >Пользователь :</p>
                                <p className={'mt-8 fnt-mull-500'}
                                   style={{color: custom.text.colorMain}}
                                >{`${personalData?.name || ''}`}</p>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography component={'span'} variant={'body2'}>
                                <p className={'mt-8 fnt-mull-500'}
                                   style={{color: custom.text.colorMain}}
                                >{personalData?.email || ''}</p>
                                <p className={'mt-8 fnt-mull-500'}
                                   style={{color: custom.text.colorMain}}
                                >{personalData?.phone || ''}</p>
                                {/*<p className={'mt-20 fnt-mull-500'}*/}
                                {/*   style={{color: custom.text.colorDesc, cursor: 'pointer'}}*/}
                                {/*><span><img src={pen}/></span>Редактировать профиль</p>*/}
                                <p className={'mt-8 fnt-mull-500'}
                                   style={{color: custom.text.colorDesc, cursor: 'pointer'}}
                                   onClick={() => logout()}
                                ><span><img src={out}/></span>Выйти из аккаунта</p>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div>
                    <Stack spacing={2}  direction={{ xs: 'column', md: 'row' }}>
                        <Button variant="contained" className={'fnt-mull-500 btn-main'}
                                sx={{
                                    bgcolor: custom.buttonHeader.bgColor,
                                    color: custom.buttonHeader.color,
                                    border: custom.buttonHeader.border,
                                    "&:hover": {
                                        color: custom.buttonHeader.hover.color,
                                        bgcolor: custom.buttonHeader.hover.bgColor,
                                    },
                                    "&:active": {
                                        color: custom.buttonHeader.active.color,
                                        bgcolor: custom.buttonHeader.active.bgColor
                                    },
                                }}
                                onClick={() => {
                                    window.location.href = 'tel:83832583300'
                                }}
                        >+7 383 258-33-00</Button>
                        <Button variant="contained" className={'fnt-mull-500 btn-main'}
                                sx={{
                                    bgcolor: custom.buttonHeader.bgColor,
                                    color: custom.buttonHeader.color,
                                    border: custom.buttonHeader.border,
                                    "&:hover": {
                                        color: custom.buttonHeader.hover.color,
                                        bgcolor: custom.buttonHeader.hover.bgColor,
                                    },
                                    "&:active": {
                                        color: custom.buttonHeader.active.color,
                                        bgcolor: custom.buttonHeader.active.bgColor
                                    },
                                }}
                                href="https://block54.ru"
                        >Перейти на сайт</Button>
                    </Stack>
                </div>
            </div>

        </div>
    );
};

export default HeaderPersonal;
