import style from './registration.module.scss'
import {Button, InputAdornment, Stack, useTheme} from "@mui/material"
import TextField from "@mui/material/TextField"
import {inputName, inputPassword} from "../../../models/static/input-validation-template"
import invalid from "../../../assets/img/invalid.svg"
import valid from "../../../assets/img/valid.svg"
import Switch from "@mui/material/Switch"
import React, {FormEvent, ReactNode, useEffect, useState} from "react"
import {Controller, useForm} from "react-hook-form"
import ReactInputMask from "react-input-mask"
import {UserAuthorization} from "../../../services/user.service"
import {RegistrationInterface} from "../../../models/types/registration-interface"
import {RegistrationStepEnum} from "../../../models/enums/confirm.enum"
import {ConfirmRegistrationPhone} from "../../../services/http.service"
import {useNavigate} from "react-router-dom"
import {responseNotificationCreator} from "../../../services/error-handler.service"

const RegistrationComponent = () => {

    const navigate = useNavigate()
    const {custom} = useTheme()

    const [stepRegistration, setStepRegistration] = useState<RegistrationStepEnum.start
        | RegistrationStepEnum.phone
        | RegistrationStepEnum.mail>(RegistrationStepEnum.start)

    const [actualPhone, setActualPhone] = useState<string>('')
    const [codeActivateInput, setCodeActivateInput] = useState<string>()
    const [codeActivate, setCodeActivate] = useState<number>()


    //TODO: types
    useEffect(() => {
        return () => {
            setStepRegistration(RegistrationStepEnum.start)
        }
    }, [])


    /*? VALIDATION-----------------------------------*/
        const {
            control, register, handleSubmit, reset, setError, clearErrors,
            formState: {
                errors, isValid, dirtyFields
            }
        } = useForm({mode: "onChange"})
        // TODO types
        const onSubmit = async (value: any) => {
            setActualPhone(value.phone)

            await UserAuthorization(value).then(({data}: RegistrationInterface | any) => {
                if (data.method === RegistrationStepEnum.phone) {
                    setStepRegistration(RegistrationStepEnum.phone)
                    setCodeActivate(Number(data.codeActivate))
                }
                if (data.method === RegistrationStepEnum.mail) {
                    setStepRegistration(RegistrationStepEnum.mail)
                }
            })
            reset()
        }

        const validateMailAndPhone = (event: any) => {
            if (event.target.name === 'mail' || !event.target.name) {
                if (!control._formValues.mail && !control._formValues.phone) {
                    setError("mail", {
                        type: "manual",
                        message: "Необходиом ввести mail или телефон"
                    })
                    setError("phone", {
                        type: "manual",
                        message: "Необходиом ввести mail или телефон"
                    })
                } else {
                    clearErrors('mail')
                    clearErrors('phone')
                }
            }
        }
    /*? VALIDATION-----------------------------------*/

    /*? CONFIRM-PHONE--------------------------------*/
        const confirm = (code: string) => {
            setCodeActivateInput(code)
            const refactorCodeInput = Number(code.replace(/ /g, ''))
            if (refactorCodeInput === codeActivate) {
                ConfirmRegistrationPhone({phone: actualPhone}).then(() => {
                    responseNotificationCreator({code: 200, message: 'Регистрация по номеру телефона прошла успешно'})
                    navigate('/auth/login-phone')
                })
            }
        }
    /*? CONFIRM-PHONE--------------------------------*/


    return (
        <div className={style.registration}>
            {
                stepRegistration === RegistrationStepEnum.start
                && <div className={style.registration__content}>
                    <h2 className={style.registration__content_heading + ' fnt-mull-400'}
                        style={{color: custom.text.colorMain}}
                    >Регистрация учетной записи</h2>
                    <form onSubmit={handleSubmit(onSubmit)} onChange={(event) => validateMailAndPhone(event)}>
                        <Stack spacing={4} direction="row" style={{marginTop: '24px'}}>
                            {/** FIRST-NAME-------------------*/}
                            <TextField
                                {...register('firstName', inputName)}
                                variant="outlined" label="Имя"
                                onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                                error={!!errors?.firstName}
                                helperText={!!errors?.firstName ? String(errors?.firstName?.message) : ''}
                                InputProps={{
                                    sx: {height: 50, width: '280px',
                                        '@media (max-width: 750px)': { width: '150px'},
                                        '@media (max-width: 450px)': {width: '150px'}},
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            {errors?.firstName && <img src={invalid}/>}
                                            {dirtyFields?.firstName && !errors?.firstName && <img src={valid}/>}
                                        </InputAdornment>
                                    )
                                }}
                            />
                            {/** LAST-NAME--------------------*/}
                            <TextField
                                {...register('lastName', inputName)}
                                variant="outlined" label="Фамилия"
                                onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                                error={!!errors?.lastName}
                                helperText={!!errors?.lastName ? String(errors?.lastName?.message) : ''}
                                InputProps={{
                                    sx: {height: 50, width: '453px',
                                        '@media (max-width: 750px)': { width: '217px'},
                                        '@media (max-width: 450px)': {width: '132px'}},
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            {errors?.lastName && <img src={invalid}/>}
                                            {dirtyFields?.lastName && !errors?.lastName && <img src={valid}/>}
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Stack>
                        <Stack spacing={3} direction="column" style={{marginTop: '30px'}}>
                            {/** PASSWORD---------------------*/}
                            <TextField
                                {...register('password', inputPassword)}
                                variant="outlined" label="Пароль"
                                onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                                error={!!errors?.password}
                                helperText={!!errors?.password ? String(errors?.password?.message) : ''}
                                InputProps={{
                                    sx: {height: 50, width: '768px',
                                        '@media (max-width: 750px)': { width: '400px'},
                                        '@media (max-width: 450px)': {width: '305px'}},
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            {errors?.password && <img src={invalid}/>}
                                            {dirtyFields?.password && !errors?.password && <img src={valid}/>}
                                        </InputAdornment>
                                    )
                                }}
                            />
                            {/** MAIL------------------------*/}
                            <TextField
                                {...register('mail', {required: false,
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: "Некорректный формат для e-mail"
                                    }})}
                                variant="outlined" label="Ваш e-mail"
                                onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                                error={!!errors?.mail}
                                helperText={!!errors?.mail ? String(errors?.mail?.message) : ''}
                                InputProps={{
                                    sx: {height: 50, width: '768px',
                                        '@media (max-width: 750px)': { width: '400px'},
                                        '@media (max-width: 450px)': {width: '305px'}},
                                }}
                            />
                            {/** PHONE-----------------------*/}
                            <Controller
                                control={control}
                                name={'phone'}
                                defaultValue=""
                                rules={{ required: false, }}
                                render={({ field }) => {
                                    return (<ReactInputMask
                                            mask="+7 999 999 99 99"
                                            disabled={false}
                                            value={field.value}
                                            onChange={field.onChange}
                                        >{(() => <TextField
                                            variant="outlined" label="Ваш номер телефона"
                                            error={!!errors?.phone}
                                            helperText={!!errors?.phone ? String(errors?.phone?.message) : ''}
                                            InputProps={{ sx: {height: 50, width: '768px',
                                                    '@media (max-width: 750px)': { width: '400px'},
                                                    '@media (max-width: 450px)': {width: '305px'}}, }}
                                        />) as any as ReactNode}
                                        </ReactInputMask>
                                    )
                                }
                                }
                            />
                        </Stack>
                        <div className={style.registration__content_checkbox}>
                            <Switch className={style.registration__content_checkbox_flag}
                                    inputProps={{ "color": "red" }}
                                    {...register('checkbox', inputPassword)}
                            />
                            <div className={style.registration__content_checkbox_desc + ' fnt-mull-400'}
                                 style={{color: errors?.checkbox ? custom.text.colorError : custom.text.colorMain}}
                            >Я ознакомился и согласился с передачей и обработкой моих персональных данных</div>
                        </div>
                        <Button variant="contained" className={style.registration__content_btn + ' fnt-mull-500 btn-main'}
                                type='submit'
                                sx={{bgcolor: custom.buttonActivity.bgColor,
                                    color: custom.buttonActivity.color,
                                    border: custom.buttonActivity.border,
                                    "&:hover": {
                                        color: custom.buttonActivity.hover.color,
                                        bgcolor: custom.buttonActivity.hover.bgColor,
                                    },
                                    "&:active": {
                                        color: custom.buttonActivity.active.color,
                                        bgcolor: custom.buttonActivity.active.bgColor
                                    },
                                }}
                        >Продолжить</Button>
                    </form>
                </div>
            }
            {
                stepRegistration === RegistrationStepEnum.phone
                && <div className={style.registration__content}>
                    <h2 className={style.registration__content_heading + ' fnt-mull-400'}
                        style={{color: custom.text.colorMain}}
                    >Введите последние четыре цифры<br/> номера телефона входящего звонка</h2>
                    <div className={style.item_input}>
                        <Controller
                            control={control}
                            name={'confirm'}
                            defaultValue={codeActivateInput}
                            render={({ field }) => {
                                return (<ReactInputMask
                                        mask="99 99"
                                        maskChar="*"
                                        disabled={false}
                                        value={field.value}
                                        onChange={(e) => confirm(e.target.value)}
                                    >{(() => <TextField
                                        variant="outlined" label="Последние 4 цифры"
                                        InputProps={{ sx: {height: 50, width: '768px',
                                                '@media (max-width: 750px)': { width: '400px'},
                                                '@media (max-width: 450px)': {width: '305px'}},}}
                                    />) as any as ReactNode}
                                    </ReactInputMask>
                                )
                            }
                            }
                        />
                    </div>
                </div>
            }
            {
                stepRegistration === RegistrationStepEnum.mail
                && <div className={style.registration__content}>
                    <h2 className={style.registration__content_heading + ' fnt-mull-400'}
                        style={{color: custom.text.colorMain}}
                    >Письмо для подтверждения учетной записи<br/> отправлено на вашу почту</h2>
                </div>
            }
        </div>
    )
}

export default RegistrationComponent
