import {createSlice} from "@reduxjs/toolkit"

//TODO types
const NotificationSlice = createSlice({
    name: 'notification',
    initialState: {
        code: null,
        isShow: false,
        message: '',
    },
    reducers: {
        setNotification: (state, action) => {
            state.code = action.payload.code
            state.isShow = action.payload.isShow
            state.message = action.payload.message
        }
    }
})

export const {setNotification} = NotificationSlice.actions
export default NotificationSlice.reducer
