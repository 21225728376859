import style from './balance.module.scss'
import {Button, InputAdornment, Stack, useTheme} from "@mui/material"
import MenuItem from '@mui/material/MenuItem'
import TextField from "@mui/material/TextField"
import valid from '../../../assets/img/valid.svg'
import invalid from '../../../assets/img/invalid.svg'
import HeaderPersonal from "../../../UI/header_personal/header-personal"
import {HeaderPersonalProps} from "../../../models/types/types-props"
import {FieldValues, useForm} from "react-hook-form"
import {inputAmount} from "../../../models/static/input-validation-template"
import {PaymentsData} from "../../../models/types/payments.interface"
import {GetAllContracts, GetPaymentsForm} from "../../../services/http.service"
import {useEffect, useState} from "react"
import {getLocalStorageItem} from "../../../services/localStore.service"
import {ContractInterface} from "../../../models/types/contract.interface"
import {useDispatch, useSelector} from "react-redux";
import {setContract} from "../../../store/features/actual-contract.slice";
import {AppDispatch} from "../../../store/features/user-data.slice";


const BalanceComponent = () => {

    const dispatch = useDispatch<AppDispatch>()
    const {custom} = useTheme()

    const [actualContract, setActualContract] = useState<string>('')
    const actualContractInStore = useSelector((state: any) => state.actualContract.number)

    const [allContract, setAllContract] = useState<ContractInterface[]>()

    const headerPersonalProps: HeaderPersonalProps = {
        heading: 'пополнить баланс',
    }

    //TODO: types
    useEffect(() => {
        setActualContract(actualContractInStore)
        const userIndex = JSON.parse(getLocalStorageItem('user'))?.index
        GetAllContracts({index: userIndex}).then((res) => {
            setAllContract(res.data)
        })
        return () => {
            dispatch(setContract({number: ''}))
        }
    }, [])

    const constructContractList = allContract?.map((contract) => {
        return <MenuItem key={contract.index} value={contract.number}>{contract.number}</MenuItem>
    })


    /*? VALIDATION-----------------------------------*/
        const {
            register, handleSubmit, reset,
            formState: {
                errors, isValid, dirtyFields
            }
        } = useForm({mode: "onChange"});
        const onSubmit = (data: FieldValues) => {
            createPayment(data)
            reset()
        }
        const changeContract = (event: string) => {
            setActualContract(event)
            /** Проверяем наличие ошибок по полю contract, поле TextField с модификайцией select
             * не отрабатывает должным образом*/
            if (errors?.contract) {
                reset({ contract: event })
            }
        }
    /*? VALIDATION-----------------------------------*/

    /*? PAYMENTS-------------------------------------*/
        const createPayment = (data: FieldValues | PaymentsData) => {
            // TODO types
            GetPaymentsForm(data).then((res: any) => {
                window.open(res.data.formUrl, '_blank')
            })
        }
    /*? PAYMENTS-------------------------------------*/


    return (
        <div>
            <div className={style.balance}>

                <HeaderPersonal {...headerPersonalProps}></HeaderPersonal>

                <div className={style.form}>
                    <h1 className={style.form_heading + ' fnt-mull-400'}
                        style={{color: custom.text.colorMain}}
                    >Ваши данные</h1>
                    <p className={style.form_desc + ' fnt-mull-400'}
                       style={{color: custom.text.colorMain}}
                    >Средства поступят на счет договора <br/> в течение 15 минут.</p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={4} direction="column">
                            {
                                allContract
                                && <TextField
                                    {...register('contract', {required: true})}
                                    variant="outlined" label="Номер договора" select
                                    value={actualContract}
                                    error={!!errors?.contract}
                                    helperText={!!errors?.contract ? String(errors?.contract?.message) : ''}
                                    onChange={(e) => changeContract(e.target.value)}
                                    sx={{height: 50, width: '100%',
                                        '@media (min-width: 600px)': { width: '400px'},
                                        '@media (min-width: 750px)': {width: '700px'}}}
                                >{constructContractList}</TextField>
                            }
                            <TextField
                                {...register('amount', inputAmount)}
                                variant="outlined" label="Введите сумму пополнения"
                                error={!!errors?.amount}
                                helperText={!!errors?.amount ? String(errors?.amount?.message) : ''}
                                InputProps={{
                                    sx: {height: 50, width: '100%',
                                        '@media (min-width: 600px)': { width: '400px'},
                                        '@media (min-width: 750px)': {width: '700px'}},
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            {errors?.amount && <img src={invalid}/>}
                                            {dirtyFields?.amount && !errors?.amount && <img src={valid}/>}
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Stack>
                        <Button variant="contained" className={style.login__activity_btn + ' fnt-mull-500 btn-main'}
                                type='submit'
                                sx={{
                                    bgcolor: custom.buttonActivity.bgColor,
                                    color: custom.buttonActivity.color,
                                    border: custom.buttonActivity.border,
                                    "&:hover": {
                                        color: custom.buttonActivity.hover.color,
                                        bgcolor: custom.buttonActivity.hover.bgColor,
                                    },
                                    "&:active": {
                                        color: custom.buttonActivity.active.color,
                                        bgcolor: custom.buttonActivity.active.bgColor
                                    },
                                }}
                        >ДАЛЕЕ</Button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default BalanceComponent;
