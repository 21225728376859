import axios from "axios"
import {AuthInterceptor} from "./auth.interceptor"
import {responseNotificationCreator} from "./error-handler.service"

export const axiosInstance = () => {

    const axiosInstanceNew = axios.create({
        baseURL: `${process.env.REACT_APP_API_URL}`,
        timeout: 10000,
        headers: AuthInterceptor()
    });

    /*? Request-interceptor----------------*/
    axiosInstanceNew.interceptors.request.use(function (config) {
        return config
    }, function (error) {
        return Promise.reject(error)
    })

    /*? Response-interceptor----------------*/
    axiosInstanceNew.interceptors.response.use(function (response) {
        return response
    }, function (error) {
        responseNotificationCreator(error)
        return Promise.reject(error)
    })

    return axiosInstanceNew
}
