import style from './payments-successful.module.scss'
import { useTheme } from "@mui/material"
import React, {useEffect} from "react"
import valid from "../../../../assets/img/valid.svg";
import {SuccessfulPayments} from "../../../../services/http.service";
import {useParams} from "react-router-dom";

const PaymentsSuccessfulComponent = () => {
    const {custom} = useTheme()
    const params = useParams()

    useEffect(() => {
        SuccessfulPayments({orderNumber: params.index}).then(res => {
            // TODO: скорей всго необходимо добавить логику при успешном плятеже(уведомление)
        })
    }, [])

    return (
        <div className={style.main}>

            <div className={style.main_content}>
                <img src={valid}/>
                <span className={style.main_content__heading + ' fnt-mull-400'}
                      style={{color: custom.text.colorMain}}
                >Платеж совершен!</span>
            </div>

        </div>
    );
};

export default PaymentsSuccessfulComponent;
