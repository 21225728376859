import {ValidationInterface} from "../types/validation.interface";

export const inputEmail: ValidationInterface = {
    required: 'Поле не  может быть пустым',
    pattern: {
        value: /\S+@\S+\.\S+/,
        message: "Некорректный формат для e-mail"
    }
}

export const inputPassword: ValidationInterface = {
    required: 'Поле не  может быть пустым',
    minLength: {
        value: 6,
        message: 'Поле должно содержать минимум 6 символов'
    },
}

export const inputName: ValidationInterface = {
    required: 'Поле не  может быть пустым',
    minLength: {
        value: 2,
        message: 'Поле должно содержать минимум 2 символа'
    },
}

export const checkbox: ValidationInterface = {
    required: true
}

export const inputAmount: ValidationInterface = {
    required: 'Поле не  может быть пустым',
    pattern: {
        value: /^[1-9]\d{1,4}?$/,
        message: "Некорректная сумма"
    }
}
