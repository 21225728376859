import { getLocalStorageItem } from "./localStore.service"

// TODO types
// TODO set new headers
export  function AuthInterceptor(): any {
    const token = JSON.parse(getLocalStorageItem('user'))?.token
    if (token) {
        return {
            'Authorization': `Bearer ${token}`,
        }
    } else {
        return {}
    }
}
