import style from './contract.module.scss'
import {Button, Stack, useTheme} from "@mui/material"
import {ContractProps} from "../../models/types/types-props"
import {useNavigate} from "react-router-dom"
import card from '../../assets/img/card.svg'
import no_protection from '../../assets/img/no_protection.svg'
import protection from '../../assets/img/protection.svg'
import {setContract} from "../../store/features/actual-contract.slice";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../store/features/user-data.slice";
import {getMonthWord} from "../../utils/utils";

const Contract = (props: ContractProps) => {

    const dispatch = useDispatch<AppDispatch>()
    const {custom} = useTheme()
    const navigate = useNavigate()

    // TODO rename to "buttonPay"
    const buttonNavigation = (rout: string): void => {
        dispatch(setContract({number: props.number}))
        navigate(rout)
    }

    return (
        <div className={style.main} style={props?.balance<= 0
            ? {border: `1px solid ${custom.static.error}`, backgroundColor: custom.static.error}
            : {border: `1px solid ${custom.static.light}`, backgroundColor: custom.static.white}}>

            <div className={style.main_heading}>
                <div className={style.main_heading_main}>
                    <h1 className={'fnt-mull-400'}
                        style={{color: custom.text.colorMain}}
                    >{props?.number}</h1>
                    <p className={'fnt-mull-400'}
                       style={{color: custom.text.colorDesc}}
                    >№ {props?.index}</p>
                </div>
                <div className={style.main_heading_desc}
                     style={{color: custom.text.colorMain}}>
                    <img src={props?.balance<= 0 ? no_protection : protection} alt="protection"/>
                    <p className={'fnt-mull-500'}
                       style={props?.balance<= 0 ? {color: custom.text.colorMin} : {color: custom.text.colorFull}}
                    >{props?.balance<= 0 ? 'Без защиты' : 'Под защитой'}</p>
                </div>
            </div>

            <div className={style.main_active}>
                <div className={style.main_active_main + ' fnt-mull-400'}
                     style={props?.balance<= 0 ? {color: custom.text.colorMin} : {color: custom.text.colorDesc}}
                >{props?.balance<= 0
                        ? 'Требуется оплата'
                        : props?.monthly_charge && props?.monthly_charge > 0
                            ? `Хватит на ` + Math.floor(props?.balance / props?.monthly_charge) + ` ` + getMonthWord(Math.floor(props?.balance / props?.monthly_charge)) : ``}
                </div>
                <div className={style.main_active_desc}>
                    <div className={'fnt-mull-400'}
                         style={props?.balance<= 0 ? {color: custom.text.colorMin} : {color: custom.text.colorFull}}
                    >{props?.balance} ₽</div>
                    <div className={style.main_active_desc_btn}>
                        <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
                            <Button variant="contained" className={style.main_active_desc_btn_item + ' fnt-mull-500'}
                                    onClick={() => buttonNavigation(`contract/${props?.index}`)}
                                    sx={{bgcolor: 'transparent',
                                        color: custom.buttonHeader.color,
                                        border: custom.buttonHeader.border,
                                        padding: '10px',
                                        height: '30px',
                                        "&:hover": {
                                            color: custom.buttonHeader.hover.color,
                                            bgcolor: custom.buttonHeader.hover.bgColor,
                                        },
                                        "&:active": {
                                            color: custom.buttonHeader.active.color,
                                            bgcolor: custom.buttonHeader.active.bgColor
                                        }
                                    }}
                            >Подробнее</Button>
                            <Button variant="contained" className={style.main_active_desc_btn_item + ' fnt-mull-500'}
                                    onClick={() => buttonNavigation('/balance')}
                                    sx={{bgcolor: custom.buttonActivity.bgColor,
                                        color: custom.buttonActivity.color,
                                        border: custom.buttonActivity.border,
                                        padding: '10px',
                                        height: '37px',
                                        "&:hover": {
                                            color: custom.buttonActivity.hover.color,
                                            bgcolor: custom.buttonActivity.hover.bgColor,
                                        },
                                        "&:active": {
                                            color: custom.buttonActivity.active.color,
                                            bgcolor: custom.buttonActivity.active.bgColor
                                        },
                                    }}
                            >Оплатить<span><img src={card} alt="card"/></span></Button>
                        </Stack>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contract
