import style from './auth.module.scss'
import logo_black from '../../assets/img/logo_black.png'
import { Button, Stack, useTheme } from "@mui/material"
import { useNavigate, Route, Routes } from "react-router-dom"
import React, { useEffect, useState } from "react"
import LoginMailComponent from "./login-mail/login-mail.component"
import LoginPhoneComponent from "./login-phone/login-phone.component"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import RegistrationComponent from "./registration/registration.component"
import {TabsMenuNameEnum} from "../../models/enums/confirm.enum"


const AuthComponent = () => {

    const navigate = useNavigate()
    const {custom} = useTheme()

    /*? NAVIGATION-----------------------------------*/
        useEffect(() => {
            if (window.location.pathname === '/auth') {
                navigate('login-mail')
                setTabValue(TabsMenuNameEnum.loginMail)
            }
            /** на случай переключения на главную страницу*/
            if (window.location.pathname === '/auth/login-mail') {
                setTabValue(TabsMenuNameEnum.loginMail)
            }
        }, [window.location.pathname])
    /*? NAVIGATION-----------------------------------*/

    /*? TAB CHANGE-----------------------------------*/
        const [tabValue, setTabValue] = useState<string>(TabsMenuNameEnum.loginMail)
        const handleTabChange = (event: React.SyntheticEvent, newValue: string): void => {
            setTabValue(newValue)
            navigate(`${newValue}`)
        }
    /*? TAB CHANGE-----------------------------------*/

    return (
        <div className={style.auth}>

            {/* ? HEADER*/}
            <div className={style.auth_header}>
                <div className={style.auth_header__heading + ' fnt-mull-500'}
                     style={{color: custom.text.colorMain}}
                     onClick={() => navigate('/auth/login-mail')}
                >Блок 54</div>
                <div className={style.auth_header__btn}>
                    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                        <Button variant="contained" className={'fnt-mull-500 btn-main'}
                                sx={{bgcolor: custom.buttonHeader.bgColor,
                                    color: custom.buttonHeader.color,
                                    border: custom.buttonHeader.border,
                                    "&:hover": {
                                        color: custom.buttonHeader.hover.color,
                                        bgcolor: custom.buttonHeader.hover.bgColor,
                                    },
                                    "&:active": {
                                        color: custom.buttonHeader.active.color,
                                        bgcolor: custom.buttonHeader.active.bgColor
                                    },
                                }}
                                onClick={() => {
                                    window.location.href = 'tel:83832583300'
                                }}
                        >+7 383 258-33-00</Button>
                        <Button variant="contained" className={'fnt-mull-500 btn-main'}
                                sx={{bgcolor: custom.buttonHeader.bgColor,
                                     color: custom.buttonHeader.color,
                                     border: custom.buttonHeader.border,
                                     "&:hover": {
                                        color: custom.buttonHeader.hover.color,
                                        bgcolor: custom.buttonHeader.hover.bgColor,
                                     },
                                     "&:active": {
                                        color: custom.buttonHeader.active.color,
                                        bgcolor: custom.buttonHeader.active.bgColor
                                     },
                                }}
                                href="https://block54.ru"
                        >Перейти на сайт</Button>
                    </Stack>
                </div>
            </div>

            {/* ? TAB MENU*/}
            <Box sx={{width: '100%'}} className={style.auth__tabs}>
                <Tabs value={tabValue}
                      onChange={handleTabChange}
                      TabIndicatorProps={{style: {background: custom.text.colorMain}}}>
                    <Tab className={style.auth__tabs_item + ' fnt-mull-500'}
                         style={{color: custom.text.colorMain, padding: '0px'}}
                         label="Вход по E-mail" value={TabsMenuNameEnum.loginMail} />
                    <Tab className={style.auth__tabs_item + ' fnt-mull-500'}
                         style={{color: custom.text.colorMain, padding: '0px'}}
                         label="Вход по телефону" value={TabsMenuNameEnum.loginPhone} />
                    <Tab className={style.auth__tabs_item + ' fnt-mull-500'}
                         style={{color: custom.text.colorMain, padding: '0px'}}
                         label="Регистрация" value={TabsMenuNameEnum.registration} />
                </Tabs>
            </Box>

            {/* ? ROUTER CONTENT*/}
            <div className={style.auth_routers}>
                {/* TODO: сделать отдельный файл для роутов, так будет красивее*/}
                    <Routes>
                        <Route path="login-mail" element={<LoginMailComponent />}></Route>
                        <Route path="login-phone" element={<LoginPhoneComponent />}></Route>
                        <Route path="registration" element={<RegistrationComponent />}></Route>
                    </Routes>
            </div>

            {/* ? FOOTER*/}
            <div className={style.auth_footer}>
                <Stack className={style.auth_footer__logo}
                       spacing={2} direction="row"
                       alignItems="center">
                    <div className={style.auth_footer__logo_img} ><img src={logo_black}/></div>
                    {/*<div className={style.auth_footer__logo_desc + ' fnt-mull-500'}*/}
                    {/*     style={{color: custom.text.colorMain}}*/}
                    {/*>Охранное предприятие Блок 54</div>*/}
                </Stack>
                <Stack className={style.auth_footer__btn}
                       spacing={2} direction="row">
                    <p className={style.auth_footer__btn_item + ' fnt-mull-500'}
                       onClick={() => navigate('/privacy-policy')}
                       style={{
                        cursor: 'pointer',
                        color: (window.location.pathname !== '/auth/registration' ? custom.buttonFooter.color: custom.text.colorMain),
                        borderBottom: (window.location.pathname !== '/auth/registration' ? custom.buttonFooter.borderBottom : custom.buttonFooter.borderBottomSec)}}
                    >Политика Конфиденциальности</p>
                    {/*TODO: добавить в будущем*/}
                    {/*<p className={style.auth_footer__btn_item + ' fnt-mull-500'} style={{*/}
                    {/*    cursor: 'pointer',*/}
                    {/*    color: (window.location.pathname !== '/auth/registration' ? custom.buttonFooter.color: custom.text.colorMain),*/}
                    {/*    borderBottom: (window.location.pathname !== '/auth/registration' ? custom.buttonFooter.borderBottom : custom.buttonFooter.borderBottomSec)}}*/}
                    {/*>Условия Пользования</p>*/}
                </Stack>
            </div>

        </div>
    )
}

export default AuthComponent
