import {createSlice} from "@reduxjs/toolkit"

//TODO types
const ActualContractSlice = createSlice({
    name: 'notification',
    initialState: {
        number: ''
    },
    reducers: {
        setContract: (state, action) => {
            state.number = action.payload.number
        }
    }
})

export const {setContract} = ActualContractSlice.actions
export default ActualContractSlice.reducer
