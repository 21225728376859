// TODO check all confirm for delete
export enum ConfirmEnum {
    confirmEmail,
    confirmPass,
    confirmFinish,
}

export enum TabsMenuNameEnum {
    loginPhone = 'login-phone',
    loginMail = 'login-mail',
    registration = 'registration',
}

export enum RegistrationStepEnum {
    start = 'start',
    phone = 'phone-registration',
    mail = 'mail-registration',
}
