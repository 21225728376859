import style from './login-phone.module.scss'
import {useNavigate} from "react-router-dom"
import {Button, InputAdornment, Stack, useTheme} from "@mui/material"
import {Controller, useForm} from "react-hook-form"
import {UserLogin} from "../../../services/user.service"
import TextField from "@mui/material/TextField"
import invalid from "../../../assets/img/invalid.svg"
import valid from "../../../assets/img/valid.svg"
import ReactInputMask from "react-input-mask"
import {ReactNode, useState} from "react"
import {LoginPhone} from "../../../services/http.service"
import {LoginMethodEnum} from "../../../models/enums/login-method-enum"


const LoginPhoneComponent = () => {

    const navigate = useNavigate()
    const {custom} = useTheme()

    const [stepConfirm, setStepConfirm] = useState<boolean>(false)

    const [actualPhone, setActualPhone] = useState<string>('')

    const [codeActivate, setCodeActivate] = useState<number>()
    const [codeActivateInput, setCodeActivateInput] = useState<string>()

    /*? VALIDATION-----------------------------------*/
        const {
            control, register, handleSubmit, reset,
            formState: {
                errors, isValid, dirtyFields
            }
        } = useForm({mode: "onChange"})
        // TODO types
        const onSubmit = async (data: any) => {
            await LoginPhone(data).then((res) => {
                setActualPhone(data.phone)
                setCodeActivate(Number(res.data.codeActivate))
                setStepConfirm(true)
            })
        }
    /*? VALIDATION-----------------------------------*/

    /*? CONFIRM--------------------------------------*/
        const confirm = (code: string) => {
            setCodeActivateInput(code)
            const refactorCodeInput = Number(code.replace(/ /g, ''))
            if (refactorCodeInput === codeActivate) {
                UserLogin({phone: actualPhone}, LoginMethodEnum.phone).then(() => navigate('/'))
            }
        }
    /*? CONFIRM--------------------------------------*/


    return (
        <div className={style.login_phone}>
            {
                !stepConfirm
                && <><h2 className={style.login_phone__heading + ' fnt-mull-400'}
                         style={{color: custom.text.colorMain}}
                >Вход в личный кабинет<br/> по номеру телефона</h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                            <Stack spacing={3} direction="column">
                                <Controller
                                    control={control}
                                    name={'phone'}
                                    defaultValue=""
                                    rules={{
                                        required: 'Поле не  может быть пустым',
                                    }}
                                    render={({ field }) => {
                                        return (<ReactInputMask
                                                mask="+7 999 999 99 99"
                                                disabled={false}
                                                value={field.value}
                                                onChange={field.onChange}
                                            >{(() => <TextField
                                                variant="outlined" label="Ваш номер телефона"
                                                error={!!errors?.phone}
                                                helperText={!!errors?.phone ? String(errors?.phone?.message) : ''}
                                                InputProps={{
                                                    sx: {height: 50, width: '100%',
                                                        '@media (min-width: 600px)': { width: '400px'},
                                                        '@media (min-width: 750px)': {width: '700px'}},
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            {errors?.phone && <img src={invalid}/>}
                                                            {dirtyFields?.phone && !errors?.phone && <img src={valid}/>}
                                                        </InputAdornment>
                                                    )}}
                                            />) as any as ReactNode}
                                            </ReactInputMask>
                                        )
                                    }
                                    }
                                />
                            </Stack>

                        <Button variant="contained" className={style.login_phone__btn + ' fnt-mull-500 btn-main'}
                                type='submit'
                                sx={{bgcolor: custom.buttonActivity.bgColor,
                                    color: custom.buttonActivity.color,
                                    border: custom.buttonActivity.border,
                                    "&:hover": {
                                        color: custom.buttonActivity.hover.color,
                                        bgcolor: custom.buttonActivity.hover.bgColor,
                                    },
                                    "&:active": {
                                        color: custom.buttonActivity.active.color,
                                        bgcolor: custom.buttonActivity.active.bgColor
                                    },
                                }}
                        >Продолжить</Button>
                    </form>
                    <div className={style.login_phone__desc + ' fnt-mull-400'}
                         onClick={() => navigate('/payment-without-registration')}
                    >Вы так же можете пополнить баланс&nbsp;
                        <span style={{color: custom.text.colorMain, borderBottom: `1px solid ${custom.text.colorMain}`}}
                        >без регистрации</span>
                    </div>
                </>
            }
            {
                stepConfirm
                && <><h2 className={style.login_phone__heading + ' fnt-mull-400'}
                         style={{color: custom.text.colorMain}}
                >Введите последние четыре цифры<br/> номера телефона входящего звонка</h2>
                <Stack spacing={3} direction="column">
                    <Controller
                        control={control}
                        name={'confirm'}
                        defaultValue={codeActivateInput}
                        render={({ field }) => {
                            return (<ReactInputMask
                                    mask="99 99"
                                    maskChar="*"
                                    disabled={false}
                                    value={field.value}
                                    onChange={(e) => confirm(e.target.value)}
                                >{(() => <TextField
                                    variant="outlined" label="Последние 4 цифры"
                                    InputProps={{ sx: {height: 50, width: 700}}}
                                />) as any as ReactNode}
                                </ReactInputMask>
                            )
                        }
                        }
                    />
                </Stack>
                </>
            }

        </div>
    )
}

export default LoginPhoneComponent
