import './App.scss'
import dec_left from './assets/img/bg_dec_left.svg'
import dec_right from './assets/img/bg_dec_right.svg'
import bg_screen from './assets/img/bg_screen.png'
import {theme_dark, theme_light} from "./assets/css/custom-theme"
import {useSelector} from "react-redux"
import {Snackbar, ThemeProvider} from "@mui/material"
import {useEffect, useState} from "react"
import {Route, Routes, useNavigate} from "react-router-dom"
import {UserCheck} from "./services/user.service"
import AuthComponent from "./pages/auth/auth.component"
import PersonalComponent from "./pages/personal/personal.component"
import PaymentWithoutRegistComponent from "./pages/payment-without-regist/payment-without-regist.component"
import PaymentsSuccessfulComponent
    from "./pages/payment-without-regist/payments-successful/payments-successful.component"
import PaymentsUnsuccessfulComponent
    from "./pages/payment-without-regist/payments-unsuccessful/payments-unsuccessful.component"
import PrivacyPolicyComponent from "./pages/privacy-policy/privacy-policy.component"


// TODO: задокументировать зависимости и скрипты в readme
function App() {

    const navigate = useNavigate()

    /*? ERROR-NOTIFICATION-------------------------*/
        //TODO types
        let codeNotification = useSelector((state: any) => state.notification.code)
        let isShowNotification = useSelector((state: any) => state.notification.isShow)
        let messageNotification = useSelector((state: any) => state.notification.message)
    /*? ERROR-NOTIFICATION-------------------------*/

    /*? NAVIGATION-----------------------------------*/
        useEffect(() => {
            setTimeout(() => {
                if (!window.location.pathname.includes('payment-without-registration')) {
                    if (!JSON.parse(UserCheck())
                        || !JSON.parse(UserCheck()).token) {
                        navigate('/auth')
                    }
                }
            }, 100)
        }, [])
    /*? NAVIGATION-----------------------------------*/

    /*? CHANGE_THEME---------------------------------*/
        // TODO: в будующем использовать функционал для смены темы
        const [activeTheme, setActiveTheme] = useState<string>('light');
        // const [checked, setChecked] = useState(true);
        // const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        //     setChecked(event.target.checked);
        //     checked ? setActiveTheme('dark') : setActiveTheme('light')
        // };
    /*? CHANGE_THEME---------------------------------*/

    /*? HIDE BG--------------------------------------*/
        const constructBackground = (): JSX.Element => {
            return (
                <div className="app_bg">
                    <div className="app_bg__left"></div>
                    <div className="app_bg__right">
                        <div className="app_bg__right_content">
                            <img src={bg_screen}/>
                            <p className="app_bg__right_content_desc fnt-mull-400"
                               style={{color: '#FE9F43'}}
                            >Вся информация о вашем имуществе,<br></br> теперь в одном месте!</p>
                        </div>
                        <img src={dec_left} className="app_bg__right_dec_left"/>
                        <img src={dec_right} className="app_bg__right_dec_right"/>
                    </div>
                </div>
            )
        }
    /*? HIDE BG--------------------------------------*/

    return (
        <div className="app">

            {/*TODO: в будующем использовать функционал для смены темы*/}
            {/*<Switch*/}
            {/*    checked={checked}*/}
            {/*    onChange={handleChange}*/}
            {/*    inputProps={{ 'aria-label': 'controlled' }}*/}
            {/*/>*/}

            {
                (window.location.pathname === '/auth/login-mail'
                    || window.location.pathname === '/auth/login-phone')
                && constructBackground()
            }

            <div className="app_content">
                <ThemeProvider theme={activeTheme === 'light' ? theme_light : theme_dark}>
                    {/* TODO: сделать отдельный файл для роутов, так будет красивее*/}
                    <Routes>
                        <Route path="/*" index element={<PersonalComponent/>}></Route>
                        <Route path="auth/*" element={<AuthComponent />}></Route>
                        <Route path="payment-without-registration/*" element={<PaymentWithoutRegistComponent />}></Route>
                        <Route path="payment-without-registration-successful/:index" element={<PaymentsSuccessfulComponent />}></Route>
                        <Route path="payment-without-registration-unsuccessful/:index" element={<PaymentsUnsuccessfulComponent />}></Route>
                        <Route path="privacy-policy" element={<PrivacyPolicyComponent />}></Route>
                    </Routes>
                </ThemeProvider>
            </div>

            <Snackbar
                open={isShowNotification}
                message={messageNotification}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />

        </div>
    )
}

export default App
