import style from './privacy-policy.module.scss'
import React, {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import {useTheme} from "@mui/material"
import {getLocalStorageItem} from "../../services/localStore.service"

const PrivacyPolicyComponent = () => {

    const navigate = useNavigate()
    const {custom} = useTheme()

    const [isAuthorization, setIsAuthorization] = useState<boolean>(false)

    useEffect(() => {
        if (getLocalStorageItem('user')) {
            setIsAuthorization(true)
        }
    })


    return (
        <div className={style.privacy_policy}>

            <div className={style.privacy_policy__header + ' fnt-mull-500'}
                 style={{color: custom.text.colorMain}}
                 onClick={() => navigate(isAuthorization ? '/' : '/auth/login-mail')}
            >Блок 54</div>

            <div className={style.privacy_policy__heading + ' fnt-mull-400'}
                 style={{color: custom.text.colorMain}}
            >ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ДЛЯ САЙТА lk.block54.ru</div>

            <div className={style.privacy_policy__body + ' fnt-mull-400'}
                 style={{color: custom.text.colorMain}}>
                <div style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between"
                }}>
                    <div>г. Новосибирск </div>
                    <div>"20" сентября 2023 г.</div>
                </div>
                <br/>
            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                Настоящая Политика конфиденциальности персональных данных (далее - Политика конфиденциальности) является неотъемлемой частью Публичной оферты, размещеннойна сайте в сети Интернет по адресу: lk.block54.ru (далее - Сайт).
            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'}
                 style={{color: custom.text.colorMain}}>
                Использование сервисов Сайта означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней условиями обработки его персональнойинформации; в случае несогласия с этими условиями Пользователь должен воздержаться от использования сервисов.
            </div>
            <div className={style.privacy_policy__heading_h3 + ' fnt-mull-400'}
                 style={{color: custom.text.colorMain}}
            >1. ОБЩИЕ ПОЛОЖЕНИЯ</div>

            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                1.1. В рамках настоящей Политики под персональной информацией Пользователя понимаются:
            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                1.1.1. Персональная информация, которую Пользователь предоставляет о себе самостоятельно при регистрации (создании учетной записи) или в процессе использованияСервисов, включая персональные данные Пользователя. Обязательная для предоставления Сервисов информация помечена специальным образом.
            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                1.1.2. Данные, которые автоматически передаются сервисам Сайта в процессе их использования с помощью установленного на устройстве Пользователя программногообеспечения, в том числе IP-адрес, данные файлов cookie, информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ ксервисам), технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к сервисам, адреса запрашиваемыхстраниц и иная подобная информация.
            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                1.1.3. Настоящая Политика конфиденциальности применяется только к Сайту lk.block54.ru. Сайт lk.block54.ru не контролирует и не несет ответственности за сайтытретьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте lk.block54.ru.
            </div>

            <div className={style.privacy_policy__heading_h3 + ' fnt-mull-400'}
                 style={{color: custom.text.colorMain}}
            >2. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ</div>

            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                2.1. Сайт собирает и хранит только ту персональную информацию, которая необходима для предоставления сервисов или исполнения соглашений и договоров с Пользователем, за исключением случаев, когда законодательством предусмотрено обязательное хранение персональной информации в течение определенного законом срока.В случае получения уведомления от Пользователя об отзыве согласия на обработку персональных данных Сайт прекращает обработку персональных данных Пользователя в срок, не превышающий 10 рабочих дней с момента получения.
            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                2.2. Персональную информацию Пользователя Сайт обрабатывает в следующих целях:

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                2.2.1. Идентификации Пользователя, зарегистрированного на Сайте.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                2.2.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                2.2.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработку запросов и заявок от Пользователя.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                2.2.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                2.2.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                2.2.6. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием Сайта.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                2.2.7. Осуществления рекламной деятельности с согласия Пользователя.

            </div>

            <div className={style.privacy_policy__heading_h3 + ' fnt-mull-400'}
                 style={{color: custom.text.colorMain}}
            >3. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ И ЕЕ ПЕРЕДАЧИ ТРЕТЬИМ ЛИЦАМ</div>

            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                3.1. Сайт хранит персональную информацию Пользователей в соответствии с внутренними регламентами конкретных сервисов.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                3.2. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц. При использовании отдельных сервисов Пользователь соглашается с тем, что определенная часть его персональной информации становится общедоступной.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                3.3. Сайт вправе передать персональную информацию Пользователя третьим лицам в следующих случаях:

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                3.3.1. Пользователь выразил согласие на такие действия.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                3.3.2. Передача необходима для использования Пользователем определенного сервиса либо для исполнения определенного соглашения или договора с Пользователем.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                3.3.4. Передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                3.3.5. В случае продажи Сайта к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                3.4. Обработка персональных данных Пользователя осуществляется без ограничения срока следующими способами: сбор, запись, систематизация, накопление, хранение,уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление,уничтожение персональных данных, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использованиятаких средств. Обработка персональных данных Пользователей осуществляется в соответствии с Федеральным законом от 27.07.2006 N 152-ФЗ "О персональных данных" .

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                3.5. При утрате или разглашении персональных данных Администрация Сайта информирует Пользователя об утрате или разглашении персональных данных.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                3.6. Администрация Сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного илислучайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                3.7. Администрация Сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванныхутратой или разглашением персональных данных Пользователя.

            </div>

            <div className={style.privacy_policy__heading_h3 + ' fnt-mull-400'}
                 style={{color: custom.text.colorMain}}
            >4. ОБЯЗАТЕЛЬСТВА СТОРОН</div>


            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                4.1. Пользователь обязан:

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                4.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                4.1.2. Обновлять, дополнять предоставленную информацию о персональных данных в случае изменения данной информации.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                4.2. Администрация Сайта обязана:

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                4.2.1. Использовать полученную информацию исключительно для целей, указанных в настоящей Политике конфиденциальности.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                4.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением предусмотренных настоящей Политикой конфиденциальности.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                4.2.3. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки в случае выявления недостоверных персональных данных или неправомерных действий.

            </div>

            <div className={style.privacy_policy__heading_h3 + ' fnt-mull-400'}
                 style={{color: custom.text.colorMain}}
            >5. ОТВЕТСТВЕННОСТЬ СТОРОН</div>


            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                5.1. Администрация Сайта, не исполнившая свои обязательства, несет ответственность за убытки, понесенные Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                5.2. В случае утраты или разглашения конфиденциальной информации Администрация Сайта не несет ответственности, если данная конфиденциальная информация:

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                5.2.1. Стала публичным достоянием до ее утраты или разглашения.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                5.2.2. Была получена от третьей стороны до момента ее получения Администрацией Сайта.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                5.2.3. Была разглашена с согласия Пользователя.

            </div>


            <div className={style.privacy_policy__heading_h3 + ' fnt-mull-400'}
                 style={{color: custom.text.colorMain}}
            >6. РАЗРЕШЕНИЕ СПОРОВ</div>


            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                6.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем Сайта и Администрацией Сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                6.2. Получатель претензии в течение пятидесяти календарных дней со дня получения претензии письменно уведомляет заявителя претензии о результатах рассмотрения претензии.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                6.3. При недостижении соглашения спор будет передан на рассмотрение в суд в соответствии с действующим законодательством Российской Федерации.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                6.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией Сайта применяется действующее законодательство Российской Федерации.

            </div>


            <div className={style.privacy_policy__heading_h3 + ' fnt-mull-400'}
                 style={{color: custom.text.colorMain}}
            >7. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</div>


            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                7.1. Администрация Сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                7.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности.

            </div>
            <div className={style.privacy_policy__body + ' fnt-mull-400'} style={{color: custom.text.colorMain,}}>
                7.3. Действующая Политика конфиденциальности размещена на странице по адресу: https://lk.block54.ru/privacy-policy.

            </div>


        </div>
    )
}
export default PrivacyPolicyComponent
