import { createTheme, Shadows, Theme } from "@mui/material";


export const theme_light: Theme = createTheme({
    custom: {
        buttonHeader: {
            color: '#013054',
            bgColor: '#FFFFFF',
            border: '1px solid #013054',
            hover: {
                color: '#59778E',
                bgColor: '#FFFFFF',
            },
            active: {
                color: '#FFFFFF',
                bgColor: 'transparent',
            }
        },
        buttonActivity: {
            color: '#FFFFFF',
            bgColor: '#013054',
            border: 'none',
            hover: {
                color: '#FFFFFF',
                bgColor: '#59778E',
            },
            active: {
                color: '#FFFFFF',
                bgColor: '#AAB9C4',
            }
        },
        buttonFooter: {
            color: '#FE9F43',
            borderBottom: '1px solid #FE9F43',
            borderBottomSec: '1px solid #013054',
        },

        // inputBig: {
        //     maxWidth: '755px',
        // },

        text: {
            colorMain: '#013054',
            colorLite: '#FFFFFF',
            colorDesc: '#59778E',
            colorFull: '#FE9F43',
            colorMin : '#FF6550',
            colorError: '#d32f2f',
        },
        static: {
            white: '#FFFFFF',
            disabled: '#818181',
            light: '#e0e0e0',
            error: '#FFF6F5',
        }
    },

    shadows: Array(25).fill('none') as Shadows,
    typography: {
        button: {
            textTransform: 'none',
        }
    },
});

export const theme_dark: Theme = createTheme({
    custom: {
        btn: '#44115d' ,

        buttonHeader: {
            color: '#013054',
            bgColor: '#FFFFFF',
            border: '1px solid #013054',
            hover: {
                color: '#59778E',
                bgColor: '#FFFFFF',
            },
            active: {
                color: '#FFFFFF',
                bgColor: 'transparent',
            }
        },
        buttonFooter: {
            color: '#FE9F43',
            borderBottom: '1px solid #FE9F43'
        },



        text: {
            colorMain: '#013054',
        }
    },

    shadows: Array(25).fill('none') as Shadows,
    typography: {
        button: {
            textTransform: 'none',
        }
    },
});

