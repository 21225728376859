import style from './my-services.module.scss'
import {ContractProps, HeaderPersonalProps} from "../../../models/types/types-props"
import HeaderPersonal from "../../../UI/header_personal/header-personal"
import Contract from "../../../UI/contract_my-services/contract"
import NewService from "../../../UI/new-service_my-services/new-service"
import {useEffect, useState} from "react"
import {GetAllContracts} from "../../../services/http.service"
import {getLocalStorageItem} from "../../../services/localStore.service"

const MyServicesComponent = () => {
    //TODO: types
    const [userData, setUserData] = useState<any>(null)
    const headerPersonalProps: HeaderPersonalProps = {
        heading: 'мои услуги',
    }

    //TODO: types
    useEffect(() => {
        const userIndex = JSON.parse(getLocalStorageItem('user'))?.index
        GetAllContracts({index: userIndex}).then((data) => {
            destructAndSaveData(data)
        })
    }, [])

    // TODO rename a function
    const destructAndSaveData = (allData: any) => {
        setUserData(allData.data)
    }

    const construct = () => {
        const contractConstruct = userData?.map((itemContacts: ContractProps, index: number) => {
            const contractProps: ContractProps = {
                index: itemContacts.index,
                address: itemContacts.address,
                balance: itemContacts.balance,
                number: itemContacts.number,
                monthly_charge: itemContacts.monthly_charge,
                tariff: itemContacts.tariff,
            }
            return(
                <div key={index} className={style.my_services__content_item}>
                    <Contract {...contractProps}></Contract>
                </div>
            )
        })
        return (
            <div className={style.my_services__content}>
                {contractConstruct}
                <div className={style.my_services__content_item}>
                    <NewService></NewService>
                </div>
            </div>
        )
    }


    return (
        <div>
            <div className={style.my_services}>

                <HeaderPersonal {...headerPersonalProps}></HeaderPersonal>

                <div>{construct()}</div>

            </div>
        </div>
    )
}

export default MyServicesComponent
